<template>
	<div class="grid justify-content-center">
		<div class="col-8">
			<div class="card">
				<h5 class="text-center">Kérdőív rendszer - Adatmegtekintő</h5>
				<div>
					<div class="grid formgrid justify-content-center">
						<div class="card">
							<h6 class="text-center">Kérjük írja be a kérdőív kódját</h6>
							<div class="col-12 text-center">
								<span class="p-input-icon-left p-input-icon-right">
									<i class="pi pi-question-circle" v-on:click="InfoSubmit()" />
									<InputText v-model="infoidtemp" type="text" placeholder="Keresés" @keyup.enter="InfoSubmit()" />
									<i class="pi pi-search" v-on:click="InfoSubmit()"/>
								</span>
							</div>
							<div class="col-12 mt-4 mb-2 text-center" v-if="message">
								<span>Kérjünk adjon meg másik kérdőív kódot, mivel jelenleg ez a kérdőív nem elérhető!</span>
							</div>

						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="col-12" v-if="ok">
			<Toast/>
			<div class="card">
				<Toolbar class="mb-4">
					<template v-slot:start>
					</template>
					<template v-slot:end>
						<Button label="Export" icon="pi pi-upload" class="p-button-help" @click="exportExcel()"  />
					</template>
				</Toolbar>

				<DataTable ref="dt" 
							:value="answers" 
							:selection="selectedAnswers" 
							dataKey="id" 
							:paginator="true" 
							:rows="10" 
							:filters="filters"
							filter-display="menu" 
							:loading="loading"
							:row-hover="true"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" 
							:rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mutasd az {first} az {last} az {totalRecords} elemet."
							:global-filter-fields="['code', 'name']"							
							responsiveLayout="scroll">
					<template #header>
						<div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
							<h5 class="m-0">Kérdések és Válaszok</h5>
							<span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="filters['global'].value" placeholder="Keresés..." />
                            </span>
						</div>
					</template>
					<template #empty>
						Nincsenek válaszok.
					</template>
					<template #loading>
						Jelenleg betöltöm az adatokat. Kérem várjon.
					</template>					

					<Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<Column field="id" header="Válasz sorszáma" :sortable="true" filter-match-mode="contains">
						<template #body="slotProps">
							<span class="p-column-title"></span>
							{{slotProps.data.id}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Keresés kód" />
						</template>
					</Column>
					<Column field="user" header="User" :sortable="true" >
						<template #body="slotProps">
							<span class="p-column-title">Kérdés</span>
							{{slotProps.data.user}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Keresés kód" />
						</template>						
					</Column>
					<Column field="date" header="Date" :sortable="true" >
						<template #body="slotProps">
							<span class="p-column-title">{{slotProps.data.ctime}}</span>
							{{slotProps.data.ctime}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Keresés kód" />
						</template>						
					</Column>
					<Column v-for="(infofield, index) in qparts" :key="infofield.id" :field="'q'+ infofield.questionnairequestionid" :header="'Kérdés-'+ infofield.questionnairequestionid" :sortable="true" >
						<template #body="slotProps">
							<span class="p-column-title">{{slotProps.data.qparts[index].answer}}</span>
							{{slotProps.data.qparts[index].answer}}
						</template>
						<template #filter="{filterModel}">
							<InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="Keresés kód" />
						</template>						
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>

import {FilterMatchMode, FilterOperator} from 'primevue/api';
import QuestionnarieService from '../service/QuestionnarieService.js';
// import axios from 'axios'

export default {
	data() {
		return {
			infoid: this.$route.query.link,
			infoidtemp : null,
			loading: true,
			infotrue: false,
			message: "",
			questionnarie: null,
			questionnarieLoad: false,
			submitted: false,
			answers: null,
			selectedAnswers: null,
			filters: {},
			qparts: {},
			ok: false,
		}
	},
	mounted() {
		if (this.infoid && this.infoid.length > 0) {
			this.infoidtemp = this.infoid;
			this.loadInfo(this.infoid)

		} else {
			this.loading = false;
		}
	},
	created() {
		this.questionnarieService = new QuestionnarieService();
		this.initFilters();
	},
	methods: {
		MoreElement(q) {
			q.repeatnumber++;
		},
		loadInfo(link) {
			this.ok = false;
			this.questionnarieService.getQuestionView(link).then(data => {
				// console.log ("DATA-OK",data)
				if (data.code != "200") {
					this.message = "Hibás kérdőÍvkód!";
				} else {
					this.answers = data.data.qas;
					if (this.answers.length > 0 && this.answers[0].qparts != undefined) {
						this.qparts = this.answers[0].qparts;
						this.ok = true;
						this.questionnarieLoad = true;
					} else {
						this.message = "Nincsen még adat a kérdőívben!";
					}
					// console.log ("A",this.answers)
					// console.log( "Q", this.qparts)
				}
				this.loading = false;
			});
			return false
		},
		InfoID(name, name2) {
			return name + "_" + name2
		},
		InfoSubmit() {
			this.loading = true;
			this.loadInfo(this.infoidtemp)
		},
		initFilters() {
            this.filters = {
                'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
				"code": {
					operator: FilterOperator.AND,
					constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
				},
				"name": {
					operator: FilterOperator.AND,
					constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
				}

            }
        },
		exportCSV() {
			this.$refs.dt.exportCSV();
		},
		exportExcel() {
			fetch("/backend/questionnaire/downloadquestionnaire/"+this.infoid, {
						method: 'GET',
						headers: new Headers({
//							"Authorization": "Bearer " + token
						})
					})
					.then(response => response.blob())
					.then(blob => {
						var url = window.URL.createObjectURL(blob);
						var a = document.createElement('a');
						a.href = url;
						a.download = "filename.xlsx";
						document.body.appendChild(a);
						a.click();    
						a.remove();
					});
		}
	},
}
</script>

<style scoped>

</style>
